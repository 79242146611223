<template>
    <div class="google-map new-updated-design" ref="childref">
        <div class="map-container full-screen" id="mapContainer">

        </div>
    </div>
</template>
<script>
    import mapboxgl from "mapbox-gl";
    import API from '@/citizen_api'
    import {mapState} from "vuex";
    import moment from 'moment'

    export default {
        ...mapState(["click"]),
        data() {
            return {
                accessToken: 'pk.eyJ1Ijoid2F0Y2hzcG90dGVyIiwiYSI6ImNsMmY0OTgxdzA3MW0zYm1jMmY5MGY5OG4ifQ.vEle7r52YhgPJ8D-MVlZ2A',
                latitude: '',
                longitude: '',
                map: [],
                mapFeatures: [],
                ready: false,
                selectedFile: [],
                url: null,
                selectedFile2: [],
                url2: null,
                selectedFile3: [],
                data2: [],
                url3: null,
                marker: null,
                appImage: null,
                isDisabled: false,
                imageError: false,
                requirePhoto: 1,
                clients_url: '',
                spotForm: {
                    requireLatLong: 1,
                    spot_type_id: '',
                    location_id: '',
                    detail: '',
                    spot_date: '',
                    latitude: '',
                    longitude: '',
                    questions: [],
                    client_url: '',
                },
                questionArray: [],
                spot_types: [],
                locations: [],
                categories: [],
            };
        }, beforeMount() {

            var client_id = this.$route.params.id
            var url = window.location.origin + '/project/' + client_id;
            this.clients_url = url;
            this.spotForm.client_url = url;
            this.getAppSetting();
            this.getClientSpots();
            this.getSpotType();
            this.getLocations();
            this.dataCollectionQuestions();
        },
        mounted() {

            var date_time = moment().format('YYYY-MM-DDTHH:mm');
            console.log(date_time);
            this.spotForm.spot_date = date_time;
            this.$store.watch(
                (state) => {
                    return this.$store.state.click // could also put a Getter here
                },
                (newValue, oldValue) => {
                    //something changed do something
                    if (newValue === 1) {
                        this.openAddSpot();
                    }
                },
//Optional Deep if you need it
                {
                    deep: true
                }
            )
        },
        methods: {
            initMap(lngLat) {
                mapboxgl.accessToken = this.accessToken;
                this.map = new mapboxgl.Map({
                    container: "mapContainer",
                    style: "mapbox://styles/mapbox/light-v10",
                    center: lngLat,
                    zoom: 14,
                });
                // more initialization
                this.spotForm.longitude = lngLat[0];
                this.longitude = lngLat[0];
                this.spotForm.latitude = lngLat[1];
                this.latitude = lngLat[1];
                this.marker = new mapboxgl.Marker({
                    draggable: true
                }).setLngLat(lngLat).addTo(this.map);


                var ref = this;
                this.marker.on('dragend', function (e) {
                    var lngLat = e.target.getLngLat();
                    // this.$refs.marker.mapObject.getLatLng();
                    ref.getLatLong(lngLat)

                })


                var loadFeatures = this.mapFeatures;
                const geojson = {
                    'type': 'FeatureCollection',
                    'features': this.mapFeatures
                };

                const nav = new mapboxgl.NavigationControl();
                this.map.addControl(nav, "top-right");


                this.map.on('load', () => {
                    this.data2 = {
                        "type": "FeatureCollection",
                        "crs": {
                            "type": "name",
                            "properties": {
                                "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
                            }
                        },
                        "features": this.mapFeatures
                    }
                    this.map.addSource('earthquakes', {
                        type: 'geojson',
                        data: this.data2,
                        cluster: true,
                        clusterMaxZoom: 14,
                        clusterRadius: 50
                    });

                    this.map.addLayer({
                        id: 'clusters',
                        type: 'circle',
                        source: 'earthquakes',
                        filter: ['has', 'point_count'],
                        paint: {
                            'circle-color': [
                                'step',
                                ['get', 'point_count'],
                                '#51bbd6',
                                100,
                                '#f1f075',
                                750,
                                '#f28cb1'
                            ],
                            'circle-radius': [
                                'step',
                                ['get', 'point_count'],
                                20,
                                100,
                                30,
                                750,
                                40
                            ]
                        }
                    });

                    this.map.addLayer({
                        id: 'cluster-count',
                        type: 'symbol',
                        source: 'earthquakes',
                        filter: ['has', 'point_count'],
                        layout: {
                            'text-field': '{point_count_abbreviated}',
                            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                            'text-size': 12,
                            "text-allow-overlap" : true

                        },
                        paint: {
                            "text-color": "#ffffff"
                        }
                    });
                    this.map.loadImage(
                        this.appImage,
                        (error, image) => {
                            console.log(image);
                            if (error) throw error;
// Add the image to the map style.
                            this.map.addImage('cat', image);
                        });

                    this.map.addLayer({
                        id: 'unclustered-point',
                        type: 'symbol',
                        source: 'earthquakes', // reference the data source
                        filter: ['!', ['has', 'point_count']],
                        // layout: {
                        //     'icon-image': 'cat', // reference the image
                        //     'icon-size': 0.25
                        // }
                        // paint: {
                        //     "text-color": "#d21010",
                        //     "icon-halo-color": "#d21010",
                        //     "icon-halo-width": 2
                        // },
                        layout: {
                            "icon-image": [
                                "match",
                                ["get", "spot_id"],
                                ["CD", "HP", "BD", "KW", "RD", "UD", "WB", "WS", "SD"], "dolphin",
                                ["MW", "UB", "HU", "PW", "SP", "NB", "FW", "CB", "SW"], "whale",
                                ["BS"], "baskingshark",
                                ["UC", "UN", "OR", "SU"], "",
                                "cat"
                            ],
                            "icon-padding": 2,
                            'icon-size': 0.05,
                            "icon-anchor": "bottom",
                            "icon-allow-overlap": {
                                "base": 1,
                                "stops": [
                                    [0, true],
                                    [12, true],
                                    [22, true]
                                ]
                            },
                        }

                    });


                    this.map.on('click', 'clusters', (e) => {
                        const features = this.map.queryRenderedFeatures(e.point, {
                            layers: ['clusters']
                        });
                        const clusterId = features[0].properties.cluster_id;
                        this.map.getSource('earthquakes').getClusterExpansionZoom(
                            clusterId,
                            (err, zoom) => {
                                if (err) return;

                                this.map.easeTo({
                                    center: features[0].geometry.coordinates,
                                    zoom: zoom
                                });
                            }
                        );
                    });

                    this.map.on('click', 'unclustered-point', (e) => {
                        console.log(e);
                        const coordinates = e.features[0].geometry.coordinates.slice();
                        const spot_type = e.features[0].properties.title;
                        const description = e.features[0].properties.description;
                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }
                        new mapboxgl.Popup()
                            .setLngLat(coordinates)
                            .setHTML(
                                `<table class="table table-sm table-bordered">
                                 <tr>
                                 <td>SPECIES </td>
                                 <td>${spot_type}</td>
                                  </tr>
                                    <tr>
                                    <td>Located at: </td>
                                    <td>${description}</td>
                                    </tr>
                                </table>`)
                            .addTo(this.map);
                    });

                    this.map.on('mouseenter', 'clusters', () => {
                        this.map.getCanvas().style.cursor = 'pointer';
                    });
                    this.map.on('mouseleave', 'clusters', () => {
                        this.map.getCanvas().style.cursor = '';
                    });

                })
            },
            getClientSpots() {
                console.log('ok')
                const data = {
                    client_url: this.clients_url,
                }
                API.getClientSpots(
                    data,
                    data => {

                        data.data.forEach(data => {
                            const d1 = {
                                type: "Feature",
                                properties: {
                                    id: data.id,
                                    title: data.spot_type.name,
                                    spot_id: data.spot_type_id,
                                    description: data.location.name,
                                    iconUrl: this.$server_url + data.image,
                                },
                                geometry: {
                                    type: "Point",
                                    coordinates: [data.longitude, data.latitude]
                                }
                            }

                            this.mapFeatures.push(d1);
                        })

                        if (navigator.geolocation) {

                            navigator.geolocation.getCurrentPosition(e => {
                                this.initMap([e.coords.longitude, e.coords.latitude])
                            })
                        } else {

                            this.initMap([-123.813866, 39.452717]);
                        }
                    },
                    err => {
                    }
                )
            },
            openAddSpot() {
                this.$store.commit('decrement');
                this.$root.$emit("bv::show::modal", "modal-addSpot");
            }, getLatLong(e) {
                this.spotForm.longitude = e['lng'];
                this.spotForm.latitude = e['lat'];
            },
            onFileSelected(event) {
                this.selectedFile = event.target.files[0]
                this.url = URL.createObjectURL(this.selectedFile)
                this.imageError = false;
            },
            onFileSelected1(event) {
                this.selectedFile2 = event.target.files[0]
                this.url2 = URL.createObjectURL(this.selectedFile2)
            },
            onFileSelected2(event) {
                this.selectedFile3 = event.target.files[0]
                this.url3 = URL.createObjectURL(this.selectedFile3)
            },
            getSpotType() {
                const data = {
                    client_url: this.clients_url,
                }
                API.getSpotType(
                    data,
                    data => {
                        this.spot_types = data.data;
                    },
                    err => {
                    }
                )

            },
            getAppSetting() {
                const data = {
                    client_url: this.clients_url,
                }
                API.getAppSetting(
                    data,
                    data => {
                        console.log(data.data);
                        this.appImage = data.data.stock_image;
                        localStorage.setItem('app_setting', JSON.stringify(data.data));
                        this.requirePhoto = data.data.require_photo;
                        this.spotForm.requireLatLong = data.data.require_lat_lang;
                        console.log(this.requirePhoto);
                    },
                    err => {
                    }
                )

            },
            getLocations() {
                const data = {
                    client_url: this.clients_url,
                }
                API.getLocations(
                    data,
                    data => {
                        this.locations = data.data;
                    },
                    err => {
                    }
                )

            },
            dataCollectionQuestions() {
                const data = {
                    client_url: this.clients_url,
                }
                API.dataCollectionQuestions(
                    data,
                    data => {
                        this.categories = data.data;
                    },
                    err => {
                    }
                )

            },
            addQuestions(event, question_id, category_id) {
                const data = {
                    category_id: category_id,
                    question_id: question_id,
                    answer: event.target.value,
                }
                this.spotForm.questions.push(data)
            },
            registerSpot() {
                this.$v.$touch();
                this.$v.spotForm.$touch();


                if (this.requirePhoto === 1 && this.selectedFile.length === 0) {
                    this.imageError = true;
                }


                if (this.$v.spotForm.$anyError || this.imageError) {
                    return;
                }
                this.isDisabled = true;
                const fd = new FormData();
                if (this.selectedFile !== undefined && this.selectedFile.length !== 0) {
                    fd.append('image', this.selectedFile, this.selectedFile.name)
                }


                if (this.selectedFile2 !== undefined && this.selectedFile2.length !== 0) {
                    fd.append('image1', this.selectedFile2, this.selectedFile2.name)
                }

                if (this.selectedFile3 !== undefined && this.selectedFile3.length !== 0) {
                    fd.append('image2', this.selectedFile3, this.selectedFile3.name)
                }


                fd.append('spot_type_id', this.spotForm.spot_type_id);
                fd.append('location_id', this.spotForm.location_id);
                fd.append('detail', this.spotForm.detail);
                fd.append('spot_date', this.spotForm.spot_date);

                if (this.spotForm.latitude === '' || this.spotForm.latitude === null) {
                    fd.append('latitude', this.latitude);
                } else {
                    fd.append('latitude', this.spotForm.latitude);
                }

                if (this.spotForm.longitude === '' || this.spotForm.longitude === null) {
                    fd.append('longitude', this.longitude);
                } else {

                    fd.append('longitude', this.spotForm.longitude);
                }


                fd.append('questions', JSON.stringify(this.spotForm.questions));
                fd.append('client_url', this.clients_url);


                this.isDisabled = true;
                API.addSpot(
                    fd,
                    data => {
                        this.isDisabled = false;

                        console.log(data.data);
                        const d1 = {
                            type: "Feature",
                            properties: {
                                id: data.data.id,
                                title: data.data.spot_type.name,
                                spot_id: data.data.spot_type_id,
                                description: data.data.location.name,
                                iconUrl: this.$server_url + data.data.image,
                            },
                            geometry: {
                                type: "Point",
                                coordinates: [data.data.longitude, data.data.latitude]
                            }
                        }
                        this.data2.features.push(d1);
                        this.map.getSource("earthquakes").setData(this.data2);

                        this.spotForm.spot_type_id = '';
                        this.spotForm.location_id = '';
                        this.spotForm.detail = '';
                        this.spotForm.spot_date = '';
                        this.spotForm.questions = [];
                        this.selectedFile = [];
                        this.selectedFile2 = [];
                        this.selectedFile3 = [];
                        this.url = '';
                        this.url2 = '';
                        this.url3 = '';
                        this.$root.$emit("bv::hide::modal", "modal-addSpot");
                        this.$snotify.success(data.message, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    },
                    err => {
                        this.isDisabled = false;
                    }
                )
            },
            changePinLong(event) {
                this.spotForm.longitude = event;
                this.marker.setLngLat([this.spotForm.longitude, this.spotForm.latitude]).addTo(this.map);
            },
            changePinLat(event) {
                this.spotForm.latitude = event;
                this.marker.setLngLat([this.spotForm.longitude, this.spotForm.latitude]).addTo(this.map);
            },
            openFullScreenMap() {
                let route = this.$router.resolve({path: '/project/map/', params: {id: 'Cl-864196'}});
                // let route = this.$router.resolve('/link/to/page'); // This also works.
                window.open(route.href, '_blank');
            }
        }
    }
</script>
<style lang="scss" scoped>
    .basemap {
        width: 100%;
        height: 100%;
    }

</style>
